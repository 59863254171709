<template>
    <div class="vstack gap-3">
        <div class="bg-white rounded">
            <div class="d-flex align-items-center p-3 border-bottom">
                <div class="h5 mb-0 bg-green text-white rounded-circle lh-1" style="padding: 0.5em;">01</div>
                <h5 class="m-0 ms-2 text-purple">Relatório Atendimentos</h5>
            </div>
            <div class="d-flex justify-content-center p-5" v-if="loadingData">
                <b-spinner></b-spinner>
            </div>
            <div class="p-2" v-else-if="chartData.labels && chartData.labels.length">
                <!-- <ChartBar :height="125" :data="chartData"/> -->
                <ChartLine :height="250" :data="chartData" :borderColor="'#000'" :pointRadius="4" :hitRadius="5" :xAxes="lineChart.xAxes" :yAxes="lineChart.yAxes" :legendLabel="lineChart.legendLabel" :legend="lineChart.legend" :tension="lineChart.tension" :hiddenLegends="lineChart.hiddenLegends" :hiddenItems="types.filter(el => el.hidden)" :update="updateChart" />
                <div class="d-flex justify-content-center gap-2" v-if="types.length">
                    <div class="d-flex gap-1 align-items-center" v-for="item in types" :key="item.color" @click="hideType(item)">
                        <div class="border border-3 border-dark" :style="`background-color: ${item.color}; height: 1em; width: 3em`"></div>
                        <div :class="`text-secondary ${item.hidden && 'text-decoration-line-through'}`" style="cursor:default;">
                            {{ item.name }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-center text-white p-3" v-else-if="hasSearched">
                Nenhum dado encontrado!
            </div>
            <div class="d-flex align-items-center p-3 w-100">
                <b-row class="m-0 w-100 align-items-end">
                    <b-col class="p-0 pe-3">
                        <b-form-group label="Período">
                            <b-form-select class="w-100 rounded" style="border-color: #ccc; padding: 0.4em 0" v-model="filters.date">
                                <b-form-select-option value="1">Hoje</b-form-select-option>
                                <b-form-select-option value="2">Esta Semana</b-form-select-option>
                                <b-form-select-option value="3">Este Mês</b-form-select-option>
                                <b-form-select-option value="4">Definir</b-form-select-option>
                            </b-form-select>
                        </b-form-group>
                        <div class="w-100 mt-2" v-if="filters.date==4">
                            <div class="w-50 pe-1 d-inline-block">
                                <b-form-group label="De:">
                                    <b-form-input type="date" v-model="filters.startDate"></b-form-input>
                                </b-form-group>
                            </div>
                            <div class="w-50 ps-1 d-inline-block">
                                <b-form-group label="Até:">
                                    <b-form-input type="date" v-model="filters.endDate"></b-form-input>
                                </b-form-group>
                            </div>
                        </div>
                    </b-col>
                    <b-col class="p-0 pe-3">
                        <b-form-group>
                            <b-form-radio-group v-model="filters.type">
                                <b-form-radio class="d-inline-flex me-3" value="1">
                                    <div class="ms-2">Departamentos</div>
                                </b-form-radio>
                                <b-form-radio class="d-inline-flex" value="2">
                                    <div class="ms-2">Operadores</div>
                                </b-form-radio>
                            </b-form-radio-group>
                        </b-form-group>
                        <v-select label="name" multiple :reduce="name => name?._id" :options="[{ _id: 'all', name: 'Todos' }, ...departments]" @input="setDepartments" v-model="filters.typeIds" v-if="filters.type=='1'" />
                        <v-select label="name" multiple :reduce="name => name?._id" :options="[{ _id: 'all', name: 'Todos' }, ...operators]" @input="setOperators" v-model="filters.typeIds" v-else-if="filters.type=='2'" />
                        <!-- <b-form-select class="w-100 rounded" style="border-color: #ccc; padding: 0.4em 0" v-model="filters.typeId" v-if="filters.type=='1'">
                            <b-form-select-option :value="null">Todos</b-form-select-option>
                            <b-form-select-option :value="dep._id" v-for="dep in departments" :key="dep._id">{{dep.name}}</b-form-select-option>
                        </b-form-select>
                        <b-form-select class="w-100 rounded" style="border-color: #ccc; padding: 0.4em 0" v-model="filters.typeId" v-else-if="filters.type=='2'">
                            <b-form-select-option :value="null">Todos</b-form-select-option>
                            <b-form-select-option :value="op._id" v-for="op in operators" :key="op._id">{{op.name}}</b-form-select-option>
                        </b-form-select> -->
                        <b-form-select class="w-100 rounded" style="border-color: #ccc; padding: 0.4em 0" v-model="filters.typeId" disabled v-else></b-form-select>
                    </b-col>
                    <b-col class="p-0 pe-3">
                        <b-form-group label="Status de Atendimento">
                            <b-form-select class="w-100 rounded" style="border-color: #ccc; padding: 0.4em 0" v-model="filters.attendanceStatus">
                                <b-form-select-option :value="null">Todos</b-form-select-option>
                                <b-form-select-option value="abandoned">Abandonados</b-form-select-option>
                                <b-form-select-option value="opened" :disabled="!!filters?.typeId">Auto Atendimento</b-form-select-option>
                                <b-form-select-option value="in_attendance">Em Atendimento</b-form-select-option>
                                <b-form-select-option value="offline_operators">Fila de Espera</b-form-select-option>
                                <b-form-select-option value="waiting">Aguardando Atendimento</b-form-select-option>
                                <b-form-select-option value="finished">Finalizados</b-form-select-option>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col class="p-0">
                        <b-button class="w-100" style="margin-top: 1.85em" variant="primary" @click="getData()">Gerar</b-button>
                    </b-col>
                </b-row>
            </div>
        </div>
        <b-row class="m-0 gap-3" v-if="user?.channelConfig?.modules?.products && !loadingData && hasSearched">
            <b-col class="d-flex flex-column gap-2 align-items-center py-4-5 px-4 rounded" style="background-color: hsl(231.67deg 37.11% 61.96%)">
                <b-iconstack font-scale="6">
                    <b-icon stacked icon="circle-fill" style="color: hsl(230.34deg 78.38% 78.24%)"></b-icon>
                    <b-icon stacked icon="coin" scale="0.7" style="color: hsl(231.67deg 37.11% 61.96%)" />
                </b-iconstack>
                <div class="text-white text-center fs-5">
                    Total de vendas do período
                </div>
                <div class="fw-semibold fs-5" style="color: hsl(230.34deg 78.38% 78.24%)">
                    R$ {{ salesData?.totalValue?.toLocaleString('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2}).replace(".", ",") || "0,00" }}
                </div>
                <div class="fs-6" style="color: hsl(230.34deg 78.38% 83.24%)" v-if="lastFilters?.date">
                    <span v-if="lastFilters.date == 1">
                        Hoje
                    </span>
                    <span v-else-if="lastFilters.date == 2">
                        Esta Semana
                    </span>
                    <span v-else-if="lastFilters.date == 3">
                        Este Mês
                    </span>
                    <span v-else>
                        <span v-if="lastFilters.startDate && lastFilters.endDate">
                            {{ lastFilters.startDate | date("DD/MM/YYYY", true) }} - {{ lastFilters.endDate | date("DD/MM/YYYY", true) }}
                        </span>
                    </span>
                </div>
            </b-col>
            <b-col class="d-flex flex-column gap-2 align-items-center py-4-5 px-4 rounded" style="background-color: hsl(9.36deg 56.63% 51.18%)">
                <b-iconstack font-scale="6">
                    <b-icon stacked icon="circle-fill" style="color: hsl(9.83deg 100% 77.25%)"></b-icon>
                    <b-icon stacked icon="handbag" scale="0.6" style="color: hsl(9.36deg 56.63% 51.18%)"></b-icon>
                </b-iconstack>
                <div class="text-white text-center fs-5">
                    Quantidade de vendas do período
                </div>
                <div class="fw-semibold fs-5" style="color: hsl(9.83deg 100% 77.25%)">
                   {{ salesData?.orders?.length ? salesData.orders.length.toString().padStart(2, "0") : 0 }}
                </div>
                <div class="fs-6" style="color: hsl(9.83deg 100% 82.25%)" v-if="lastFilters?.date">
                    <span v-if="lastFilters.date == 1">
                        Hoje
                    </span>
                    <span v-else-if="lastFilters.date == 2">
                        Esta Semana
                    </span>
                    <span v-else-if="lastFilters.date == 3">
                        Este Mês
                    </span>
                    <span v-else>
                        <span v-if="lastFilters.startDate && lastFilters.endDate">
                            {{ lastFilters.startDate | date("DD/MM/YYYY", true) }} - {{ lastFilters.endDate | date("DD/MM/YYYY", true) }}
                        </span>
                    </span>
                </div>
            </b-col>
            <b-col class="d-flex flex-column gap-2 align-items-center py-4-5 px-4 rounded" style="background-color: hsl(86.97deg 35.46% 49.22%)">
                <b-iconstack font-scale="6">
                    <b-icon stacked icon="circle-fill" style="color: hsl(85.71deg 55.68% 65.49%)"></b-icon>
                    <b-icon stacked icon="sort-up" scale="0.6" style="color: hsl(86.97deg 35.46% 49.22%)" />
                </b-iconstack>
                <div class="text-white text-center fs-5">
                    Ticket Médio
                </div>
                <div class="fw-semibold fs-5" style="color: hsl(85.71deg 55.68% 65.49%)">
                    R$ {{ salesData?.averageTicket?.toLocaleString('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2}).replace(".", ",") || "0,00" }}
                </div>
            </b-col>
        </b-row>
        <div class="bg-white rounded" v-if="hasSearched">
            <div class="d-flex align-items-center justify-content-between p-3">
                <div class="d-flex align-items-center">
                    <div class="h5 mb-0 bg-green text-white rounded-circle" style="padding: 0.5em;">02</div>
                    <h5 class="m-0 ms-2 text-purple">Relatório Listagem de Atendimento</h5>
                </div>
                <div class="d-flex align-items-center">
                    <div class="text-nowrap">Total de Atendimentos</div>
                    <div class="fs-5 bg-purple text-white rounded-pill mx-3 px-2">{{ tableItems.count || 0 }}</div>
                </div>
            </div>
            <div class="border-top">
                <div class="bg-white d-flex justify-content-center p-5" v-if="loadingData">
                    <b-spinner></b-spinner>
                </div>
                <div v-else-if="tableItems.attendances && tableItems.attendances.length">
                    <b-table class="reportContacts m-0" tbody-tr-class="align-middle" hover head-variant="dark" sort-icon-left :items="tableItems.attendances" :fields="tableFields">
                        <template #cell(attendanceEndTime)="data">
                            {{ data.item.attendanceEndTime | date('MMM DD [●] HH:mm:ss') }}
                        </template>
                        <template #cell(attendanceStartTime)="data">
                            {{ data.item.attendanceStartTime | date('MMM DD [●] HH:mm:ss') }}
                        </template>
                        <template #head(orderId)="data">
                            <div class="hstack gap-2" role="button" @click="sortTableBySale()">
                                <span class="w-min">{{ data.label }}</span>
                                <b-icon class="text-secondary darkenTextOnHover" font-scale="1.25" :icon="tableSaleSortIcon"></b-icon>
                            </div>
                        </template>
                        <template #cell(orderId)="data">
                            <div class="hstack gap-2" v-if="data.item.orderId">
                                <span class="text-success">
                                    Sim
                                </span>
                                <b-button class="bg-success border-0 rounded-circle" style="padding: .2em .3em" v-b-toggle="`sale${data.item._id}`" v-b-tooltip.hover.bottom="{ variant: 'success', customClass: 'top-0', boundary: 'document' }" title="Dados da venda">
                                    <b-icon icon="chevron-down" scale="0.75"></b-icon>
                                </b-button>
                            </div>
                            <div class="text-red" v-else>
                                Não
                            </div>
                        </template>
                        <template #head(messages)>{{""}}</template>
                        <template #cell(messages)="data">
                            <div class="d-flex justify-content-end">
                                <b-button class="border-0 px-4 me-2" pill @click="selectClient(data.item); getForwardList()" v-b-modal.modalForward v-b-tooltip.hover.bottom="{ customClass: 'top-0', boundary: 'document' }" title="Encaminhar Atendimento" v-if="data.item.status == 'finished' || data.item.status == 'abandoned' || data.item.status == 'in_survey'">
                                    <b-icon icon="box-arrow-in-right"></b-icon>
                                </b-button>
                                <b-button class="bg-green border-0 px-4" pill @click="selectClient(data.item); loadMessages(data.item._id,data.item.operatorId)" v-b-modal.contactMessages v-b-tooltip.hover.bottom="{ variant: 'green', customClass: 'top-0', boundary: 'document' }" title="Visualizar Atendimento">
                                    <b-icon icon="chat-square-text"></b-icon>
                                </b-button>
                            </div>
                        </template>
                        <template #cell(clientName)="data">
                            <div class="d-flex align-items-center">
                                <div class="me-2">
                                    <b-avatar class="bg-secondary text-white" size="3rem" :src="data.item.photoURL"></b-avatar>
                                </div>
                                <div class="text-truncate">
                                    {{ data.item.clientName }} 
                                </div>
                            </div>
                        </template>
                        <template #row-details="data">
                            <b-collapse class="w-100 hstack gap-2 py-3 px-4 bg-grey" :id="`sale${data.item._id}`">
                                <div class="vstack gap-3" v-if="data.item.orderData">
                                    <div>
                                        <b-row class="m-0">
                                            <b-col class="p-0 fw-semibold">
                                                Número do pedido
                                            </b-col>
                                            <b-col class="p-0 fw-semibold">
                                                Nome do cliente
                                            </b-col>
                                            <b-col class="p-0 fw-semibold">
                                                Valor total
                                            </b-col>
                                            <b-col class="p-0 fw-semibold">
                                                Forma de pagamento
                                            </b-col>
                                        </b-row>
                                        <b-row class="m-0">
                                            <b-col class="p-0">
                                                {{ data.item.orderData.orderId }}
                                            </b-col>
                                            <b-col class="p-0 text-truncate">
                                                {{ data.item.clientName }}
                                            </b-col>
                                            <b-col class="p-0">
                                                R$ {{ data.item.orderData.totalPrice?.toLocaleString('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2}).replace(".", ",") || "0,00" }}
                                            </b-col>
                                            <b-col class="p-0 text-truncate">
                                                {{ data.item.orderData.paymentMethod || '- - - -' }}
                                            </b-col>
                                        </b-row>
                                    </div>
                                    <div class="vstack gap-2 bg-light rounded-1 p-2">
                                        <div>
                                            <b-row class="m-0 py-2 gap-2 border-bottom" v-for="item in data.item.orderData.dataItens" :key="item.skuId">
                                                <b-col class="p-0" cols="1">
                                                    <b-img class="w-100 h-100" :src="item.image" v-if="item.image" />
                                                    <b-skeleton-img class="w-100 h-100" animation="" no-aspect v-else></b-skeleton-img>
                                                </b-col>
                                                <b-col class="p-0">
                                                    <div class="text-truncate fw-semibold fs-6">
                                                        {{ item.name }}
                                                    </div>
                                                    <div class="fs-5">
                                                        R$ {{ item.price?.toLocaleString('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2}).replace(".", ",") || "0,00" }}
                                                    </div>
                                                    <div>
                                                        Quantidade: {{ item.quantity }}
                                                    </div>
                                                </b-col>
                                            </b-row>
                                        </div>
                                        <div class="d-flex justify-content-between gap-2">
                                            <div class="fs-5">
                                                <span>
                                                    Total:
                                                </span>
                                                <span class="fw-semibold">
                                                    R$ {{ data.item.orderData.totalPrice?.toLocaleString('pt-BR', {minimumFractionDigits: 2, maximumFractionDigits: 2}).replace(".", ",") || "0,00" }}
                                                </span>
                                            </div>
                                            <div class="vstack align-items-end gap-2">
                                                <div>
                                                    <span>
                                                        Valor do frete:
                                                    </span>
                                                    <span class="fw-semibold">
                                                        R$ {{ data.item.orderData.totalValues?.find(el => el.name === 'Total do Frete')?.value.replace(".", ",") || '0,00' }}
                                                    </span>
                                                </div>
                                                <div>
                                                    <span>
                                                        Valor da Taxa:
                                                    </span>
                                                    <span class="fw-semibold">
                                                        R$ {{ data.item.orderData.totalValues?.find(el => el.name === 'Total da Taxa')?.value.replace(".", ",") || '0,00' }}
                                                    </span>
                                                </div>
                                                <div>
                                                    <span>
                                                        Desconto:
                                                    </span>
                                                    <span class="fw-semibold">
                                                        R$ {{ data.item.orderData.totalValues?.find(el => el.name === 'Total dos Descontos')?.value.replace(".", ",") || '0,00' }}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </b-collapse>
                        </template>
                    </b-table>
                    <div class="d-flex justify-content-between align-items-center px-4 py-3" v-if="tableItems && tableItems.count > 10">
                        <b-pagination class="m-0"
                            :total-rows="tableItems.count"
                            v-model="currentPage"
                            :per-page="perPage"
                            @change="pageChange"
                        ></b-pagination>
                        <span class="pagdescription">
                            <div class="text-secondary">{{ ( currentPage > 1 ) ? ((currentPage) * perPage) - perPage + 1 : currentPage }} - {{ (currentPage * perPage > tableItems.count) ? tableItems.count : currentPage * perPage }} de {{ tableItems.count }} atendimentos</div>
                        </span>
                    </div>
                </div>
                <div class="text-center p-3 text-secondary" v-else>
                    Nenhum dado encontrado!
                </div>
            </div>
        </div>
        <b-modal id="contactMessages" ref="contactMessages" title="Visualizar Atendimento" header-class="py-0" hide-footer>
            <div class="d-flex align-items-center mb-2" v-if="clientSelected">
                <b-avatar class="bg-secondary text-white" size="3rem" :src="clientSelected.photoURL"></b-avatar>
                <div class="text-purple fw-bold ms-2">
                    {{ clientSelected.clientName }} 
                </div>
            </div>
            <div>
                <Whatsapp :messages="contactMessages" :height="'72vh'" :mediaURL="mediaURL" />
            </div>
        </b-modal>
        <b-modal id="modalForward" ref="modalForward" hide-footer title="Encaminhar Atendimento" header-class="py-0" v-if="clientSelected">
            <b-form-group label="Encaminhar Para:" label-class="text-purple fw-semibold">
                <b-form-select class="w-100 rounded p-2 mt-3 border-grey" v-model="forward.operator">
                    <b-form-select-option :value="op" v-for="op in onlineOperators" :key="op._id">{{ op.name }}</b-form-select-option>
                </b-form-select>
            </b-form-group>
            <div class="mt-3">
                <b-button class="text-white" variant="green" @click="startAttendance()">Encaminhar</b-button>
                <b-button class="ms-2" variant="danger" @click="$bvModal.hide('modalForward')">Cancelar</b-button>
            </div>
        </b-modal>
        <b-modal id="modalContactInSurvey" ref="modalContactInSurvey" title="Contato em Pesquisa de Satisfação" body-class="p-0" header-class="py-1" hide-footer>
            <div class="vstack gap-3 p-3">
                <div class="vstack text-secondary">
                    <div class="fw-bold">
                        Este contato está em pesquisa de satisfação.
                    </div>
                    <div>
                        Deseja continuar e cancelar a pesquisa?
                    </div>
                </div>
                <div class="hstack gap-3">
                    <b-button variant="success" @click="startAttendance(true)" :disabled="startingAttendance">Continuar</b-button>
                    <b-button variant="danger" @click="$bvModal.hide('modalContactInSurvey')">Cancelar</b-button>
                </div>
            </div>
        </b-modal>
        <FAB ref="fabs" :screenshotBtn="true" :xlsBtn="true" @exportXlsData="exportXlsData" />
    </div>
</template>

<script>
// import ChartBar from './chartBar.vue'
import ChartLine from './chartLine.vue'
import Whatsapp from './whatsapp.vue'
import api from '../services/apiService.js'
import utils from '../utils/utils.js'
import FAB from './FAB.vue'

export default {
    props: [
        'user', 'timestampDiff', 'mediaURL'
    ],
    components: {
        // ChartBar, 
        ChartLine,
        Whatsapp,
        FAB
    },
    data() {
        return {
            filters: {
                date: 3,
                typeIds: []
            },
            lastFilters: null,
            clientSelected: null,
            objAux: {},
            forward: {},
            operators: [],
            onlineOperators: [],
            departments: [],
            chartData: [],
            tableFields: [],
            tableItems: [],
            currentPage: 1,
            perPage: 10,
            contactMessages: [],
            loadingData: false,
            hasSearched: false,
            types: [],
            lineChart: {
                yAxes: {
                    display: true,
                    ticks: {
                        display: true,
                    },
                    gridLines: {
                        display: true
                    }
                },
                xAxes: {
                    display: true,
                    ticks: {
                        display: true,
                    },
                    gridLines: {
                        display: true
                    }
                },
                legendLabel: true,
                legend: {
                    display: true,
                },
                tension: 0.1
            },
            salesData: null,
            updateChart: false,
            tableSaleSort: null,
            startingAttendance: false,
        }
    },
    mounted: function() {
        // this.getData()
        this.onCloseModal()
        this.getTableItems()
        this.getDepartments()
        this.getOperators()
    },
    watch: {
        "filters.type": {
            handler() {
                this.clearTypeIds()
            }
        }
    },
    computed: {
        tableSaleSortIcon() {
            const sortIcons = {
                'asc': 'arrow-up',
                'desc': 'arrow-down',
                null: 'arrow-down-up'
            };
            return sortIcons[this.tableSaleSort] || sortIcons.null;
        }
    },
    methods: {
        onCloseModal() {
            this.$root.$on('bv::modal::hide', () => {
                this.forward = {}
                this.clientSelected = null
                this.objAux = {}
                this.contactMessages = []
            })
        },
        getTableItems() {
            this.tableFields = [
            {
                    key: 'clientName',
                    label: 'Contato',
                },
                {
                    key: 'clientNumber',
                    label: 'Telefone'
                },
                {
                    key: 'attendanceStartTime',
                    label: 'Data de início do atendimento',
                },
                {
                    key: 'attendanceEndTime',
                    label: 'Data de fim do atendimento',
                },
                ...(this.user?.channelConfig?.modules?.products === true ? [{
                    key: 'orderId',
                    label: 'Venda efetivada',
                }] : []),
                {
                    key: 'messages',
                    class: ''
                }
            ]

            if (!this.user?.channelConfig) {
                setTimeout(() => {
                    this.getTableItems()
                }, 5000)
            }
        },
        async getData(page = 1, saleSort = null) {
            this.loadingData = true
            let type = this.filters.type
            if(!this.filters.typeIds.length)
                type = null

            this.lastFilters = Object.assign({}, this.filters)

            const salesResp = await this.getReportSales({ channelId: this.user.channelId || this.user.roleId, filter: { ...this.filters , type, saleSort }, firstPeriod: this.filters.startDate, finalPeriod: this.filters.endDate })
            if(salesResp) {
                // console.log({salesResp})
                this.salesData = salesResp
            } else {
                this.salesData = null
            }
            
            const resp = await this.getReportAttendance({ channelId: this.user.channelId || this.user.roleId, filter: { ...this.filters , type, saleSort }, firstPeriod: this.filters.startDate, finalPeriod: this.filters.endDate, attendanceStatus: this.filters.attendanceStatus, page })
            // console.log('resp report',resp)
            if(resp?.attendances) {
                const orderDataReps = await Promise.all(resp.attendances.map(async el => {
                    if (el.orderId) {
                        const resp = await api.getOrderById(el.orderId)
                        if (resp.result?.statusCode === 200 || resp.statusCode === 200)
                            return resp.result.order || resp.order
                    }
                    return null
                }))
                const orderDataMap = new Map(orderDataReps.map(el => [el?._id, el]))
                resp.attendances = resp.attendances.map(el => {
                    el.orderData = orderDataMap.get(el.orderId)
                    console.log(el.orderData)
                    el._showDetails = true
                    return el
                })
                this.tableItems = resp
            } else {
                this.tableItems = []
            }

            if (page == 1 && !saleSort) {
                this.tableSaleSort = null
            }

            const resp2 = await this.getReportAttendanceCount({ channelId: this.user.channelId || this.user.roleId, filter: { ...this.filters , type }, firstPeriod: this.filters.startDate, finalPeriod: this.filters.endDate, attendanceStatus: this.filters.attendanceStatus })
            // console.log('resp count',resp2)
            if(resp2) {
                const labels = []
                let values = [
                    {
                        label: "Abandonados",
                        // backgroundColor: utils.generateColor(),
                        backgroundColor: "hsl(253deg 50% 93%)",
                        data: [],
                    },
                    {
                        label: "Auto Atendimento",
                        // backgroundColor: utils.generateColor(),
                        backgroundColor: "hsl(45deg 100% 51%)",
                        data: []
                    },
                    {
                        label: "Fila de Espera",
                        // backgroundColor: utils.generateColor(),
                        backgroundColor: "hsl(244, 98%, 80%)",
                        data: []
                    },
                    {
                        label: "Em Atendimento",
                        // backgroundColor: utils.generateColor(),
                        backgroundColor: "hsl(152deg 69% 31%)",
                        data: []
                    },
                    {
                        label: "Aguardando Atendimento",
                        // backgroundColor: utils.generateColor(),
                        backgroundColor: "hsl(216deg 98% 52%)",
                        data: []
                    },
                    {
                        label: "Finalizados",
                        // backgroundColor: utils.generateColor(),
                        backgroundColor: "hsl(208deg 7% 46%)",
                        data: [],
                    },
                ]
                
                this.types = []
                if(resp2.types?.length) {
                    this.lineChart.hiddenLegends = resp2.types
                    for (const type of resp2.types) {
                        const color = utils.generateSubtleColor()
                        const obj = {
                            label: type,
                            backgroundColor: color,
                            data: [],
                            type: 'bar'
                        }
                        values.push(obj)
                        this.types.push({
                            name: type,
                            color,
                            hidden: false
                        })
                    }
                }

                this.$set(this.chartData, 'labels', labels)
                this.$set(this.chartData, 'values', values)

                for (const el of resp2.count) {
                    if(el.status?.length) {
                        values[0].data.push(this.findStatus("abandoned",el.status))
                        values[1].data.push(this.findStatus("opened",el.status))
                        values[2].data.push(this.findStatus("offline_operators",el.status))
                        values[3].data.push(this.findStatus("in_attendance",el.status))
                        values[4].data.push(this.findStatus("waiting",el.status))
                        values[5].data.push(this.findStatus("finished",el.status) + this.findStatus("in_survey",el.status))
                    } else
                        values.map(value => value.data.push(0))

                    if (el.typesCount?.length) {
                        for (const type of el.typesCount) {
                            values[values.findIndex(el => el.label === type.name)].data.push(type.count)
                        }
                        values.map((value) => {
                            if(resp2.types.includes(value.label) && !el.typesCount.some(e => e.name === value.label)) {
                                value.data.push(0)
                            }
                        })
                    } 

                    if(el.hour) {
                        const [hours, minutes] = el.hour.split(':')

                        const dateObject = new Date()
                        dateObject.setUTCHours(hours)
                        dateObject.setUTCMinutes(minutes)
                        dateObject.setUTCSeconds(0)
                        dateObject.setUTCMilliseconds(0)

                        labels.push(this.$options.filters.date(dateObject,'HH:mm'))
                        // labels.push(el.hour)
                    } else
                        labels.push(this.$options.filters.date(el.date,'DD/MM/YY',true))
                }
                
                if (resp2.count?.at(0)?.hour) {
                    const oldLabels = labels.slice()
                    labels.sort(this.compareTimes)

                    const newValues = [
                        {
                            label: values[0].label,
                            backgroundColor: values[0].backgroundColor,
                            data: []
                        },
                        {
                            label: values[1].label,
                            backgroundColor: values[1].backgroundColor,
                            data: []
                        },
                        {
                            label: values[2].label,
                            backgroundColor: values[2].backgroundColor,
                            data: []
                        },
                        {
                            label: values[3].label,
                            backgroundColor: values[3].backgroundColor,
                            data: []
                        },
                        {
                            label: values[4].label,
                            backgroundColor: values[4].backgroundColor,
                            data: []
                        },
                        {
                            label: values[5].label,
                            backgroundColor: values[5].backgroundColor,
                            data: []
                        },
                    ]

                    if(resp2.types?.length) {
                        for (const type of resp2.types) {
                            newValues.push({
                                label: type,
                                backgroundColor: '',
                                data: [],
                                type: 'bar'
                            })
                        }
                    }

                    for (const newLabel of labels) {
                        const oldIndex = oldLabels.indexOf(newLabel)
                        for(let i = 0; i < values.length; i++) {
                            // console.log(i, newValues[i])
                            if (!newValues[i])
                                newValues[i] = {
                                    label: values[i].label,
                                    backgroundColor: values[i].backgroundColor,
                                    data: [],
                                    type: 'bar'
                                }
                            newValues[i].data.push(values[i].data[oldIndex])
                        }
                    }
                    values = newValues
                }

                this.$set(this.chartData, 'labels', labels)
                this.$set(this.chartData, 'values', values)
            }
            this.loadingData = false
            this.hasSearched = true
            return
        },
        compareTimes(time1, time2) {
            const date1 = new Date(`1970-01-01T${time1}:00`)
            const date2 = new Date(`1970-01-01T${time2}:00`)
            return date1 - date2;
        },
        findStatus(status,array) {
            let find = array.find(e => e.status == status)
            if(find)
                return find.count
            else 
                return 0
        },
        async getReportAttendance(data) {
            const resp = await api.getReportAttendance(data)
            if(resp.statusCode == 200) {
                return resp.attendances
            }
            return false
        },
        async getReportAttendanceCount(data) {
            const resp = await api.getReportAttendanceCount(data)
            if(resp.statusCode == 200) {
                return resp.attendances
            }
            return false
        },
        async getReportSales(data) {
            const channelId = this.user.channelId || this.user.roleId
            const resp = await api.getReportSales(channelId, data)
            if(resp.statusCode == 200) {
                return resp.reportSales
            }
            return false
        },
        getForwardList() {
            this.getOnlineOperators()
            this.getDepartments()
        },
        async getOperators() {
            const resp = await api.getOperators(this.user.channelId || this.user.roleId)
            if(resp.statusCode != 200) {
                this.operators = []
                this.operators['total'] = 0
                return
            }

            if(this.user.role == "channel_manager" && this.user.departments?.at(0) !== 'all')
                resp.operators = resp.operators.filter(el => this.user.departments?.some(e => el.department.includes(e)))

            this.operators = resp.operators
        },
        async getOnlineOperators() {
            const resp = await api.getOnlineOperators(this.user.channelId || this.user.roleId)
            if(resp.statusCode != 200) {
                this.onlineOperators = []
                return
            }

            if(this.user.role == "channel_manager" && this.user.departments?.at(0) !== 'all')
                resp.operators = resp.operators.filter(el => this.user.departments?.some(e => el.department.includes(e)))

            this.onlineOperators = resp.operators
        },
        async getDepartments() {
            const resp = await api.getDepartments(this.user.channelId || this.user.roleId)
            if(resp.statusCode != 200) {
                this.departments = []
                this.departments['total'] = 0
                return
            }

            if(this.user.role == "channel_manager" && this.user.departments?.at(0) !== 'all')
                resp.departments = resp.departments.filter(el => this.user.departments?.includes(el._id))

            this.departments = resp.departments
        },
        async loadMessages(attendanceId, operatorId) {
            const resp = await api.loadMessages({
                attendance: {
                    _id: attendanceId
                },
                operatorId
            })

            if (resp.statusCode === 200) {
                resp.messages.messages.map(el => {
                    if(this.timestampDiff > 100)
                        el.timestamp -= this.timestampDiff
    
                    if(el.timestamp > 9999999999)
                        el.timestamp /= 1000
                })
    
                this.contactMessages = resp.messages.messages
            }
        },
        pageChange(page) {
            if(page != this.currentPage) {
                this.currentPage = page
                this.getData(page, this.tableSaleSort)
            }
        },
        async startAttendance(ignoreSurvey = false) {
            if(this.forward.operator) {
                this.startingAttendance = true

                const contact = this.clientSelected
                // in this cases, contact comes from old attendance
                if(!contact.number) contact.number = contact.clientNumber
                if(!contact.name) contact.name = contact.clientName
    
                const resp = await api.retrieveAttendance(this.forward.operator.channelId, { contact, operatorId: this.forward.operator._id, ignoreSurvey })

                this.startingAttendance = false
                
                if(this.$refs["modalContactInSurvey"])
                    this.$refs["modalContactInSurvey"].hide()

                let msg

                if(resp.statusCode == 200) {
                    msg = {
                        text: "Atendimento encaminhado com sucesso!",
                        success: true
                    }
                    if(this.$refs['modalForward'])
                        this.$refs['modalForward'].hide()
                } else if(resp.statusCode == 406) {
                    let text

                    switch(resp.attendance.status) {
                        case 'opened':
                            text = "Este contato está com atendimento em andamento no ChatBot"
                            break
                        case 'offline_operators':
                            text = "Este contato está com atendimento em andamento na fila de espera"
                            break
                        default:
                            if (resp.attendance.operatorName)
                                text = `Este contato está com atendimento em andamento com ${resp.attendance.operatorName}!`
                            else
                                text = "Este contato está com atendimento em andamento"
                    }

                    msg = {
                        text,
                        success: false
                    }
                } else if(resp.statusCode == 408) {
                    msg = {
                        text: "O horário de atendimento deste operador foi finalizado!",
                        success: false,
                        countdown: 10
                    }
                } else if(resp.statusCode === 409) {
                    return this.$bvModal.show("modalContactInSurvey")
                } else {
                    msg = {
                        text: "Ocorreu um erro!",
                        success: false
                    }
                }

                if (msg) {
                    this.$emit('msg',msg)
                }
            }
        },
        selectClient(item) {
            this.clientSelected = item
            this.objAux = Object.assign({}, item);
        },
        async exportXlsData() {
            try {
                const resp = await api.getReportAttendanceFull({ channelId: this.user.channelId || this.user.roleId, filter: this.filters, firstPeriod: this.filters.startDate, finalPeriod: this.filters.endDate, attendanceStatus: this.filters.attendanceStatus })
                if(resp.statusCode === 200) {
                    const model = resp.attendances?.attendances.map(d=>{
                        const model = {
                            Contato: d.clientName,
                            Whatsapp: d.clientNumber,
                            Inicio_atendimento: this.$options.filters.date(d.attendanceStartTime,'DD/MM/YY HH:mm:ss'),
                            Fim_atendimento: this.$options.filters.date(d.attendanceEndTime,'DD/MM/YY HH:mm:ss'),
                            Operador: d.operatorName
                        }
                        return model
                    })
                    const xls = utils.convertToXLS(model)
                    utils.downloadXLS(xls, `Relatorio-Atendimentos-${new Date().getTime()}`)
                } else {
                    console.log(resp)
                }
            } catch(error) {
                console.error(error)
            }
            this.$refs.fabs.$emit('exportedXls', true)
        },
        clearTypeIds() {
            this.filters.typeIds = []
        },
        setDepartments(input) {
            if(input.includes('all')) {
                if(input.length - 1 === this.departments.length) {
                    this.filters.typeIds = []
                } else {
                    this.filters.typeIds = []
                    this.departments.map(el => {
                        this.filters.typeIds.push(el._id)
                    })
                }
            }
            input = this.filters.typeIds
        },
        setOperators(input) {
            console.log(input, this.filters)
            if(input.includes('all')) {
                if(input.length - 1 === this.operators.length) {
                    this.filters.typeIds = []
                } else {
                    this.filters.typeIds = []
                    this.operators.forEach(el => {
                        this.filters.typeIds.push(el._id)
                    })
                }
            }
        },
        hideType(type) {
            type.hidden = !type.hidden
            const dataset = this.chartData.values.find((ds) => ds.label === type.name)
            if(dataset) {
                // eslint-disable-next-line no-extra-boolean-cast
                dataset.hidden = !!!dataset.hidden
            }
            this.updateChart = !this.updateChart
        },
        toggleTableSaleSort() {
            this.tableSaleSort = this.tableSaleSort === 'asc' ? 'desc' : 'asc'
        },
        sortTableBySale() {
            this.toggleTableSaleSort()
            this.getData(1, this.tableSaleSort)
        }
    },
}
</script>

<style>
    .reportContacts td, .reportContacts th{
        padding: 1em 1.5em !important;
    }
    .reportContacts .b-table-details td {
        padding: 0 !important
    }
</style>